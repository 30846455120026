var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.Blurb
    ? _c("div", { staticClass: "blurb-card column" }, [
        _c(
          "div",
          { staticClass: "blurb-title row" },
          [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.Blurb.number > 0 ? _vm.$t("lesson").toUpperCase() : ""
                )
              )
            ]),
            _c("span", [
              _vm._v(_vm._s(_vm.Blurb.number > 0 ? _vm.Blurb.number : ""))
            ]),
            _c("span", [_vm._v(_vm._s(_vm.Blurb.name))]),
            _vm.Blurb.audioOverview
              ? _c("AudioToggle", {
                  key: _vm.Blurb.audioOverview.id,
                  attrs: {
                    "white-background": true,
                    audio: _vm.Blurb.audioOverview
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "blurb-content" }, [
          _c("p", { domProps: { innerHTML: _vm._s(_vm.Blurb.description) } })
        ]),
        _c(
          "div",
          { staticClass: "blurb-footer" },
          [
            _vm.Blurb.essentialQuestionOverview
              ? _c("essential-question", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  key: _vm.Blurb.essentialQuestionOverview.id,
                  attrs: {
                    audio: _vm.Blurb.essentialQuestionOverview.audio,
                    message: _vm.Blurb.essentialQuestionOverview.question
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }