var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.overview
    ? _c(
        "div",
        {
          staticClass: "unit-overview row",
          style: {
            "background-image": this.overview.backgroundImageId
              ? "url(" + this.background + ")"
              : ""
          }
        },
        [
          _vm.overview.lessons.length == 0
            ? _c("span", { staticClass: "centered" }, [
                _vm._v(_vm._s(_vm.$t("noContentToDisplayMessage")))
              ])
            : [
                _c(
                  "div",
                  { staticClass: "blurbs column" },
                  [
                    _vm.previousBlurb
                      ? _c("img", {
                          key: _vm.previousBlurb.imageId,
                          staticClass: "last-checked-blurb",
                          attrs: { src: _vm.getBlurbImage(_vm.previousBlurb) },
                          on: {
                            click: function($event) {
                              _vm.updateCurrentBlurb(_vm.previousBlurb)
                            }
                          }
                        })
                      : _vm._e(),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.currentBlurb
                        ? _c("img", {
                            key: _vm.currentBlurb.imageId,
                            staticClass: "current-blurb centered drop-shadow",
                            attrs: {
                              src: _vm.getBlurbImage(_vm.currentBlurb, true)
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm.nextBlurb
                      ? _c("img", {
                          key: _vm.nextBlurb.imageId,
                          staticClass: "remaining-blurb",
                          attrs: { src: _vm.getBlurbImage(_vm.nextBlurb) },
                          on: {
                            click: function($event) {
                              _vm.updateCurrentBlurb(_vm.nextBlurb)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _vm.unit
                      ? _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.unit.name,
                                expression: "unit.name"
                              }
                            ],
                            staticClass: "overview-title",
                            style: { color: _vm.overview.titleBackgroundColor }
                          },
                          [_vm._v(_vm._s(_vm.unit.name))]
                        )
                      : _vm._e(),
                    _c("blurb-card"),
                    _c("div", { staticClass: "row" }, [
                      _vm.isBackButtonDisabled
                        ? _c(
                            "button",
                            {
                              staticClass: "btn round secondary",
                              on: {
                                click: function($event) {
                                  _vm.goToPreviousBlurb()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("back")))]
                          )
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "btn round primary",
                          on: {
                            click: function($event) {
                              _vm.goToNextBlurb()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.nextBtnText))]
                      )
                    ])
                  ],
                  1
                ),
                _c("div", {
                  staticClass: "overview-background",
                  style: _vm.overviewStyles
                })
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }