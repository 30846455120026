<template>
  <div class="unit-overview row" v-if="overview"  :style="{'background-image': this.overview.backgroundImageId ? 'url(' + this.background + ')' :''}">
    <span v-if="overview.lessons.length == 0" class="centered">{{$t('noContentToDisplayMessage')}}</span>
    <template v-else>
      <div class="blurbs column">
          <img v-if="previousBlurb" :key="previousBlurb.imageId" class="last-checked-blurb"
            :src="getBlurbImage(previousBlurb)" @click="updateCurrentBlurb(previousBlurb)">
          <transition name="fade">
              <img  v-if="currentBlurb" :key="currentBlurb.imageId" 
                :src="getBlurbImage(currentBlurb, true)" class="current-blurb centered drop-shadow">
          </transition>  
          <img v-if="nextBlurb" class="remaining-blurb"
            :key="nextBlurb.imageId" :src="getBlurbImage(nextBlurb)" 
            @click="updateCurrentBlurb(nextBlurb)">
      </div>
      <div class="column">
        <span :style="{color: overview.titleBackgroundColor}" 
              class="overview-title" 
              v-tooltip="unit.name" 
              v-if="unit">{{unit.name}}</span>
        <blurb-card/>
        <div class="row">
          <button class="btn round secondary" v-if="isBackButtonDisabled" 
            @click="goToPreviousBlurb()">{{$t('back')}}</button>
          <button class="btn round primary" @click="goToNextBlurb()">{{nextBtnText}}</button>
        </div>
      </div>
    <div class="overview-background"  :style="overviewStyles"></div>
    </template>
  </div>
</template>

<script>
import BlurbCard from '@/components/BlurbCard.vue'
export default {
  name: 'UnitOverview',
  components: {
    BlurbCard
  },
  methods: {
    updateCurrentBlurb(blurb){
      this.$store.commit('updateCurrentBlurb', blurb)
    }, 
    goToFirstLessonInUnit(){
		let route = { name: 'Unit', params: { unitId: this.unit.id }} 
	
		if(this.$store.state.isPreviewModeActive) {
      this.$router.push({ name: 'UnitBuilderPreviewMode', params: { unitId: this.unit.id }});
			return 
    }

		this.$store.dispatch("updateOverviewStatus", this.$route.params.unitId)
				.then(null, () => {
					this.$store.commit('addSnackBarNotification', {
								message: this.$t("failedToUpdateOverviewStatus"), 
								dismissable: false
							});
				})
				.finally(() => { this.$router.push(route); });
    },
    getBlurbImage(blurb, isSecondaryImage = false){
      if(!isSecondaryImage)
        return this.getImageUrl(blurb.imageName)
      return blurb.secondaryImageId 
         ? this.getImageUrl(blurb.secondaryImageName)
         : this.getImageUrl(blurb.imageName)
    }, 
    setFirstBlurb(){
      this.updateCurrentBlurb(this.$store.getters.blurbs[1])
    }, 
    goToNextBlurb(){
      if(this.isNextButtonDisabled){
        this.updateCurrentBlurb(this.nextBlurb)
        return
      } 
      // If last blurb user will be redirected
      // first lesson in unit
      this.goToFirstLessonInUnit()
    },
    goToPreviousBlurb(){
      this.updateCurrentBlurb(this.previousBlurb)
    }
  },
  computed: {
    unit(){
        return this.$store.state.currentUnit
    },
    overview(){
        return this.$store.state.overview
    },
    currentBlurb(){
      return this.$store.state.currentBlurb
    },
    indexOfCurrentBlurb(){
      return this.$store.getters.blurbs.indexOf(this.currentBlurb)
    },
    isBackButtonDisabled(){
      return this.indexOfCurrentBlurb != 0
    },
    isNextButtonDisabled(){
      return this.indexOfCurrentBlurb != this.$store.getters.blurbs.length -1
    },
    previousBlurb(){
      if(this.indexOfCurrentBlurb === 0)
        return
      return this.$store.getters.blurbs[this.indexOfCurrentBlurb -1]
    }, 
    nextBlurb(){
      if(this.indexOfCurrentBlurb === this.$store.getters.blurbs.length -1)
        return
      return this.$store.getters.blurbs[this.indexOfCurrentBlurb +1]
    },
    firstLesson(){
      return this.unit.lessons[1]
    },
    firstOverviewLesson() {
      return this.$store.getters.blurbs[1];
    },
    background(){
      return this.getImageUrl(this.overview.backgroundImageName)
    }, 
    overviewStyles(){
      if(!this.overview)
        return {'background-color': '#fff'}
      return{
        'background-color': this.overview.backgroundColor, 
        'opacity': (this.overview.opacity/100)
      }
    },
    nextBtnText() {
      return this.indexOfCurrentBlurb === this.$store.getters.blurbs.length -1
        ? this.$t("lessons")
        : this.$t("next");
    }
  }, 
  created(){
    this.$store.state.overview && this.$store.state.overview.unitId ==this.$route.params.unitId
        ? this.blurbsChecked = this.$store.getters.blurbs.filter(b => b.clicked == true).length 
        : this.$store.dispatch('loadOverview', this.$route.params.unitId)

    this.$store.commit('loadLesson', null)    
  }, 
  mounted(){
      this.startTrackingPage({ sectionName: "Unit Overview" });
      this.$store.commit("setAnalyzedManagementSection", "Unit Overview");
  },
  beforeDestroy(){
      this.$store.commit("setAnalyzedManagementSection", "");
      this.stopTrackingPage({ sectionName: "Unit Overview" });
      
  }
}
</script>

<style lang="less" scoped>

.overview-background{
  z-index: -2;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.unit-overview{
  margin: 0 auto;
  max-width: 1280px;
  min-width: 1280px;
  margin-top: 104px;
  height: 800px;
  min-height: 800px;
  -ms-flex-line-pack: center;
  align-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;


  > .column{
    &:first-child{
      width: 195px;
      margin-left: 145px;
      position: relative;
      img{
        height: auto;
        width: auto;
        max-height: 450px;
        max-width: 450px;
      }
    }
    &:nth-child(2){
      margin-left: auto;
      width: 810px;
    }
    .overview-title {
      margin-top: 28px;
      color: rgba(0,0,0,0.6);
      font-size: 33.64px;
      font-weight: 600;
      letter-spacing: 0.25px;
      line-height: 41px;
      width: auto;
      max-width: 800px;
      min-height: 130px;
      white-space: normal;
      text-overflow: initial;
      overflow: auto;
      margin-bottom: 63px;
    }

    .remaining-blurb{
      position: absolute;
      bottom: -15%;
    }

    .last-checked-blurb{
      position: absolute;
      top: -15%;
    }

    .btn{
      margin-top: 28px;
      min-height: 40px;

      &.primary{
        margin-left: auto;
        margin-right: 190px;
      }
    }
  }
}
</style>